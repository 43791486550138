import {mapState, mapMutations, mapGetters, mapActions} from 'vuex'
import {getGearName, isDacia, isDanmarkD, isDanmarkR, isRenault,} from '../helpers/helpers.js';
import {
  googleAccountDacia,
  googleAccountDanmark,
  googleAccountDanmarkDacia,
  googleAccountRenault
} from "@/config/defaultValues";

export default {
  data: () => ({
    index: null,
    active: false
  }),
  props: {
    title: {},
    buttonLabel: {}
  },
  computed: {
    ...mapState('product', ['vehicle', 'selectedAccessories']),
    ...mapGetters('product', [
      'vehicleNotEmpty',
      'deliveryDaysCountFrom',
      'deliveryDaysCountTo',
      'grade',
      'vehicleNotEmpty',
      'getPriceForm',
    ]),
    ...mapState('order', ['personNumber', 'vehicleCostId','locationId' ]),
    ...mapState('steps', ['redirectUrl', 'nfSteps', 'nfActiveStep']),
    vehicleColor: {
      get() {
        return this.$store.state.product.vehicleColor
      },
      set(value) {
        this.$store.commit('product/setVehicleColor', value)
      }
    },
    price() {
      let lowest = this.vehicle.fixedCosts.data.length ? this.vehicle.fixedCosts.data[0].price : 0;
      this.vehicle.fixedCosts.data.forEach(item => {
        lowest = item.price < lowest ? item.price : lowest
      });

      return lowest
    },
    images() {
      return this.vehicle.images ? this.vehicle.images.data : []
    },
    vehicleGear() {
      return getGearName(this.vehicle.gear);
    },
    vehicleColors() {
      if (!this.vehicleCostId) return []

      const modelColorCosts = this.vehicle.costs.data.find(
        (item) => item.id === this.vehicleCostId
      )

      const modelColorData = modelColorCosts.vehicleColors
        ? modelColorCosts.vehicleColors.data.filter((item) => {
            return !!item.modelColor.image.image
          })
        : []

      return modelColorData
    },
    hasErrors() {
      for (let field in this.fields) {
        if (this.fields[field].valid === false) {

          return true
        }
      }

      return false
    },
    vehicleTitle() {
      if (!this.vehicle?.brand || !this.vehicle?.model) {
        return '';
      }
      if (this.vehicle.model.startsWith(this.vehicle.brand)) {
        return this.vehicle.model;
      }
      return `${this.vehicle.brand} ${this.vehicle.model}`;
    },
    fullVehicleName() {
      return `${this.vehicle.brand} ${this.vehicle.model} ${this.vehicle.grade} ${this.vehicle.name}`
    },
    fullVehicleSubName() {
      return `<b>${this.vehicle.grade}</b> ${this.vehicle.name}`
    },
    localAccessories() {
      if (!this.vehicle.accessories) return [];
      return this.vehicle.accessories.data;
    },
    insurance() {
      return this.localAccessories.filter(acc => acc.type === 3)
    },
    wheelsAccessories() {
      return this.localAccessories.filter(acc => acc.type === 2)
    },
    optionalAccessories() {
      return this.localAccessories.filter(acc => acc.type === 1)
    },
    googleAcc() {
      let acc = isDanmarkR() ? googleAccountDanmark :
          isDanmarkD() ? googleAccountDanmarkDacia :
              isRenault() ? googleAccountRenault :
                  isDacia() ? googleAccountDacia : '';

      return acc
    },
    phoneRegex() {
      return isDanmarkD() || isDanmarkR() ? /^([+]45|0)([0-9]{8,18})$/ : /^([+]46|0)([0-9]{8,18})$/
    },
    gaClientId() {
      return this.getGaClientId()
    }
  },
  methods: {
    ...mapMutations('steps', ['setRedirectUrl']),
    ...mapActions('product', ['getVehicle']),
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    goToNext() {
      this.$emit('goToNext')
    },
    goToPrev() {
      this.$emit('goToPrev')
    },
    validate() {
      for (let i in this.$children) {
        this.$children[i].$validator.validate()
      }

      this.$validator.validateAll()
      for (let field in this.fields) {
        if (this.fields[field].valid === false) {
          this.$el.querySelector('[data-vv-name="' + field + '"]').scrollIntoView()
          return false
        }
      }

      if (!this.locationId && this.nfActiveStep === 7) {
        return false
      }

      return true
    },
    accessor(item) {
      return this.localAccessories.find(accessor => accessor.id === item) || {}
    },
    getGaClientId() {
      let match = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)');
      let raw = (match) ? decodeURIComponent(match[1]) : null;
      if (raw) {
        match = raw.match(/(\d+\.\d+)$/);
      }
      return (match) ? match[1] : null;
    },
  },
  beforeMount() {
    this.scrollToTop()
    this.$store.dispatch('order/fillAllFields')
  },
  mounted() {
    this.$emit('loaded', this)
  },
  beforeRouteUpdate(to, from, next) {
    if (from.params.id !== to.params.id) {
      this.$store.dispatch('order/clearAll')
      this.$store.dispatch('product/clearAll')

      next({name: 'home'})
    } else {
      if (this.validate()) {

        next()
      }
    }
  },
  watch: {
    locationId: function (n) {
      if (n) {
        this.$store.dispatch('order/saveAllFields', {'locationId': n})
      }
    },
    selectedAccessories: function (n) {
      if (n) {
        this.$store.dispatch('order/saveAllFields', {'accessories': n})
      }
    },
    pickUpTime: function (n) {
      if (n) {
        this.$store.dispatch('order/saveAllFields', {'pickUpTime': n})
      }
    },
    firstName: function (n) {
      if (n) {
        this.$store.dispatch('order/saveAllFields', {'firstName': n})
      }
    },
    lastName: function (n) {
      if (n) {
        this.$store.dispatch('order/saveAllFields', {'lastName': n})
      }
    },
    email: function (n) {
      if (n) {
        this.$store.dispatch('order/saveAllFields', {'email': n})
      }
    },
    phone: function (n) {
      if (n) {
        this.$store.dispatch('order/saveAllFields', {'phone': n})
      }
    },
    personNumber: function (n) {
      if (n) {
        this.$store.dispatch('order/saveAllFields', {'personNumber': n})
      }
    },
    companyName: function (n) {
      if (n) {
        this.$store.dispatch('order/saveAllFields', {'companyName': n})
      }
    },
    companyNumber: function (n) {
      if (n) {
        this.$store.dispatch('order/saveAllFields', {'companyNumber': n})
      }
    },
    address: function (n) {
      if (n) {
        this.$store.dispatch('order/saveAllFields', {'address': n})
      }
    },
    coAddress: function (n) {
      if (n) {
        this.$store.dispatch('order/saveAllFields', {'coAddress': n})
      }
    },
    city: function (n) {
      if (n) {
        this.$store.dispatch('order/saveAllFields', {'city': n})
      }
    },
    zipCode: function (n) {
      if (n) {
        this.$store.dispatch('order/saveAllFields', {'zipCode': n})
      }
    },
    comments: function (n) {
      if (n) {
        this.$store.dispatch('order/saveAllFields', {'comments': n})
      }
    },
    totalMonthlyPrice: function (n) {
      if (n) {
        this.$store.dispatch('order/saveAllFields', {'totalMonthlyPrice': n})
      }
    },
    status: function (n) {
      if (n) {
        this.$store.dispatch('order/saveAllFields', {'status': n})
      }
    }
  }
}
