<template>
    <div class="alert-box">
      <img
        src="@/assets/img/alert_symbol_250px.png"
        alt="Alert Icon"
        class="alert-icon"
      />
      <div class="alert-content">
        <h2 class="alert-title" v-html="title"></h2>
        <p class="alert-message" v-html="content"></p>
      </div>
    </div>
  </template>
  <script>
  export default {
    name: 'AlertBox',
    props: {
      title: {
        type: String,
        default: '',
      },
      content: {
        type: String,
        default: '',
      },
    },
  }
  </script>
  
  <style scoped>
  .alert-box {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border-radius: 0;
    box-shadow: none;
    margin: 16px auto;
    max-width: 600px;
    width: 100%;
  }
  
  .alert-icon {
    width: 54px;
    height: 54px;
    margin-right: 16px;
  }
  
  .alert-content {
    flex: 1;
  }
  
  .alert-title {
    font-size: 0.8em;
    font-weight: bold;
    margin: 0 0 2px 0;
  }
  
  .alert-message {
    font-size: 0.6em;
    margin: 0;
    line-height: 1.5;
    color: #000000;
  }
  
  .alert-message a {
    color: #0066cc;
    text-decoration: underline;
  }
  
  .alert-message a:hover {
    color: #003366;
  }
  
  @media (max-width: 768px) {
    .alert-box {
      flex-direction: row;
      align-items: center;
      padding: 4px;
      width: 100%;
      margin: 16px 0;
    }
  
    .alert-icon {
      margin-bottom: 0;
      margin-right: 16px;
      width: 40px;
      height: 40px;
    }
  
    .alert-content {
      text-align: left;
      width: calc(
        100% - 56px
      ); /* Adjust width to account for icon size and margin */
    }
  
    .alert-title {
      font-size: 0.6em;
      margin-bottom: 2px;
    }
  
    .alert-message {
      font-size: 0.5em;
    }
  }
  </style>