<template lang="pug">
.page.page-model.step-order-new
  input#GACLIENTID(type='hidden' name='GACLIENTID' v-model='gaClientId')
  input#GATRACKID(type='hidden' name='GATRACKID' v-model='googleAcc')
  .container
    .row.justify-content-between
      .col-5
        .d-md-block
    .row.justify-content-between
      .col-5
        .step-title {{ orderPageText.title }}
        .step-text.m-t-10(v-html="orderPageText.intro")
        .row.m-t-20
          .col-6
            .form-group
              input#first_name.form-control(
                type="text"
                v-model="firstName"
                placeholder=" "
                v-validate="'required|alpha_spaces'"
                data-vv-name="firstName"
                :class="{'is-invalid':errors.has('firstName')}"
              )
              label(for="first_name") {{ $t("stepOrder.firstName") }}

          .col-md-6
            .form-group
              input#last_name.form-control(
                type="text"
                v-model="lastName"
                placeholder=" "
                v-validate="'required|alpha_spaces'"
                data-vv-name="lastName"
                :class="{'is-invalid':errors.has('lastName')}"
              )
              label(for="last_name") {{ $t("stepOrder.secondName") }}

        .form-group(v-if="['Corporate leasing', 'Corporate rental', 'VAT credit'].includes(getFinanceFormName)")
          input#company_name.form-control(
            type="text"
            v-model="companyName"
            placeholder=" "
            v-validate="'required|alpha_spaces'"
            data-vv-name="companyName"
            :class="{'is-invalid':errors.has('companyName')}"
          )
          label(for="company_name") {{ $t("stepOrder.companyName") }}

        .row
          .col-md-12(v-if="['Corporate leasing', 'Corporate rental', 'VAT credit'].includes(getFinanceFormName)")
            .form-group
              the-mask.form-control(
                id="company_number"
                mask="######-####"
                v-model="companyNumber"
                placeholder=" "
                v-validate="'required|min:10|max:10'"
                data-vv-name="companyNumber"
                :class="{'is-invalid':errors.has('companyNumber')}"
              )
              label(for="company_number") {{ $t("stepOrder.organizationNumber") }}

          .col-md-12(v-if="settings.personNumber")
            .form-group
              the-mask.form-control(
                id="person_number"
                mask="########-####"
                v-model="personNumber"
                placeholder=" "
                v-validate="'required|min:12|max:12'"
                data-vv-name="personNumber"
                :class="{'is-invalid':errors.has('personNumber') || getPersonNumberError !== ''}"
              )
              label(for="person_number") {{ $t("stepOrder.securityNum") }} (ÅÅÅÅMMDD-NNNN)

            .form-group(v-if="settings.drivingLicense")
              the-mask.form-control(
                id="drivingLicense"
                mask="#########"
                v-model="drivingLicense"
                placeholder=" "
                v-validate="'required|numeric|min:9|max:9'"
                data-vv-name="drivingLicense"
                :class="{'is-invalid':errors.has('drivingLicense')}"
              )
              label(for="drivingLicense") {{ $t("stepOrder.drivingLicense") }}

            .input-group.m-t-15.m-b-20(
              v-show="getPersonNumberError !== ''"
              class="postcode_validation invalid"
            ) {{ getPersonNumberError }}

        .form-group
          input#inp6.form-control(
            type="text"
            v-model="address"
            v-validate="'required'"
            placeholder=" "
            data-vv-name="address"
            :class="{'is-invalid':errors.has('address')}"
          )
          label(for="inp6") {{ $t("stepOrder.adress") }}

        .form-group
          input#inp7.form-control(
            type="text"
            v-model="coAddress"
            placeholder=" "
            data-vv-name="coAddress"
            :class="{'is-invalid':errors.has('coAddress')}"
          )
          label(for="inp7") {{ $t("stepOrder.coAdress") }}

        .row
          .col-md-4
            .form-group
              the-mask.form-control(
                id="zip_code"
                type="text"
                v-model="zipCode"
                placeholder=" "
                v-validate="'required|numeric|min:4|max:5'"
                data-vv-name="zipCode"
                mask="#####"
                :class="{'is-invalid':errors.has('zipCode')}"
              )
              label(for="zip_code") {{ $t("stepOrder.postnum") }}

          .col-md-8
            .form-group
              input#inp10.form-control(
                type="text"
                v-model="city"
                v-validate="'required'"
                data-vv-name="city"
                placeholder=" "
                :class="{'is-invalid':errors.has('city')}"
              )
              label(for="inp10") {{ $t("stepOrder.city") }}

        .form-group
          transition(name="fade")
            div(v-if="errors.has('phone')" class="validation-hint") {{ $t("stepOrder.telephoneHint") }}
          input#inp11.form-control(
            type="tel"
            v-model="phone"
            placeholder=" "
            v-validate="{required: true, max:13, regex: phoneRegex}"
            mask="############"
            data-vv-name="phone"
            :class="{'is-invalid':errors.has('phone')}"
          )
          label(for="inp11") {{ $t("stepOrder.telephone") }}

        .form-group
          input#inp12.form-control(
            type="email"
            v-model="email"
            placeholder=" "
            v-validate="{required: true, regex:/^[a-z0-9._-]+@([a-z0-9])+[a-z0-9.-]+([.])+[a-z]{2,4}$/}"
            data-vv-name="email"
            :class="{'is-invalid':errors.has('email')}"
          )
          label(for="inp12") {{ $t("stepOrder.email") }}

        .form-group(v-if="settings.drivingLicense")
          the-mask.form-control(
            id="drivingLicense"
            mask="#########"
            placeholder=" "
            v-model="drivingLicense"
            v-validate="'required|numeric|min:9|max:9'"
            data-vv-name="drivingLicense"
            :class="{'is-invalid':errors.has('drivingLicense')}"
          )
          label(for="drivingLicense") Körkortsnummer (fält 4d på ditt körkort, 9 siffror)

        .form-group.m-t-30
          textarea#inp13.form-control(
            rows="1"
            placeholder=" "
            v-model="comments"
          )
          label(for="inp13") {{ $t("stepOrder.comment") }}


        div(v-if="resellerInfo.settings && resellerInfo.settings.homeDelivery && vehicle.home_delivery")
          .step-subtitle.m-t-20
            strong {{ customStyle.orderPage.delivery_title }}

          .row.m-t-15
            .col-md-4.col-6
              input.radio-block-input(
                type="radio"
                data-vv-name="delivery_type"
                id="delivery_type_stardard"
                value="delivery_type_stardard"
                v-model="deliveryType"
                v-validate="'required'"
                :checked="true"
              )
              label.radio-block(for="delivery_type_stardard")
                .radio-block-title {{ $t("stepGeneral.pickUpYourself") }}
                .radio-block-info {{ $t("steps.costs.standard") }}

            .col-md-4.col-6
              input.radio-block-input(
                type="radio"
                data-vv-name="delivery_type"
                id="delivery_type_home"
                value="delivery_type_home"
                v-model="deliveryType"
                v-validate="'required'"
              )
              label.radio-block(for="delivery_type_home")
                .radio-block-title {{ $t("stepGeneral.homeDelivery") }}
                .radio-block-info.red-text
                  | + {{ homeDelivery }} {{ currencySymbol }}/{{ $t('mon') }} <br>


        button.pickup-location-btn.m-t-30(data-vv-name="location" v-if="deliveryType === 'delivery_type_stardard'" v-show="!location" @click="showPickUpLocation") {{ customStyle.firstStep.location_title }}

        transition(name="fade")
          div(v-if="errors.has('location') && !locationId" class="validation-hint" :style="{position: 'relative',top: '-20px'}") {{ $t("stepGeneral.selectCollection") }}
        .pick-up-location-wrapper(:class="{'pick-up-location-wrapper--active' : showSelectPickUpLocation}")
          CloseCircle(@click="closeSelectPickUpLocation")
          .pick-up-location-title {{ $t("stepGeneral.selectCollection") }}
          .pick-up-location-container
            div(v-if="locations.length < 8")
              label.pick-up-location(v-for="(location, index) in locations" :key="index" )
                input(:id="location.id" type="radio" @change="closeSelectPickUpLocation" :checked="location.id === locationId" v-model="locationId" :value='location.id' v-validate="'required'" data-vv-name="location")
                div.selected-bg
                div.pick-up-location-textbox(:class="{'selected': location.id === locationId}")
                  div
                    div
                      b {{ location.name }} {{ location.dealer ? '- ' + location.dealer : '' }}
                  div
                    b {{ $t('select') }}
            CustomScroll.location-custom-scroll(v-else)
              label.pick-up-location(v-for="(location, index) in locations" :key="index" )
                input(:id="location.id" type="radio" @change="closeSelectPickUpLocation" :checked="location.id === locationId" v-model="locationId" :value='location.id' v-validate="'required'" data-vv-name="location")
                div.selected-bg
                div.pick-up-location-textbox(:class="{'selected': location.id === locationId}")
                  div
                    div
                      b {{ location.name }} {{ location.dealer ? '- ' + location.dealer : '' }}
                  div
                    b {{ $t('select') }}

        .selected-pick-up-location.m-t-30(v-if="location")
          div.font-weight-bold {{ $t("info.collectionPoint") }}
          div.d-flex.align-items-center.justify-content-between
            span {{ location.name || '-'}} {{ location.dealer ? '- ' + location.dealer : '' }}
            button.change-location-btn.btn-hover.invert(@click="showPickUpLocation") {{ $t("stepOrder.change") }}


        div(v-if="deliveryType === 'delivery_type_home'")
          .step-subtitle.m-t-20

          .form-group.d-flex
            the-mask.form-control(
              mask="#####"
              data-vv-name="postcode"
              placeholder=" "
              v-validate="'required|numeric|min:5|max:5'"
              v-model="postcode"
              :class="{'is-invalid':errors.has('postcode')}"
              :error="errors.first('postcode')"
              @input="postcodeInput"
            )
            label(for="postcode") {{ $t("stepGeneral.postnumber") }}

            button.btn.btn-general(
              :disabled="errors.has('postcode')"
              @click.prevent="checkPostCode"
            ) {{ $t("stepGeneral.checkPostcode") }}

          div
            .m-t-15.m-b-20.invalid(
              v-if="errors.has('postcode')"
            ) {{ $t("stepGeneral.postcodeMinValid") }}

            .input-group.m-t-15.m-b-20(
              :class="{'postcode_validation': !waitingForResponse}"
            ) {{ $t("stepGeneral.checking") }}...

            .input-group.m-t-15.m-b-20(
              class="postcode_validation"
              :class="{'invalid': postcode_validation === 'invalid'}"
            ) {{ $t("stepGeneral.homeDeliveryError") }}

            .input-group.m-t-15.m-b-20(
              class="postcode_validation"
              :class="{'valid': postcode_validation === 'valid'}"
            ) {{ $t("stepGeneral.homeDeliverySuccess") }}

        div(
          v-if="isRental(this.priceForm)"
        )
          .step-subtitle.m-t-20 {{ customStyle.orderPage.delivery_title }}
          .row.row-date-time.m-b-20
            .col-md-6
              .input-group.input-group_date-time.m-t-15
                .input-group-prepend(style="width: 100%")
                  span.input-group-text
                    i.far.fa-calendar-alt
                  datepicker(
                    ref="datePicker"
                    :class="{'is-invalid': errors.has('pickUpDate')}"
                    placeholder="Välj ett datum"
                    v-validate="'required'"
                    data-vv-name="pickUpDate"
                    :disabled-dates="disabledDates"
                    v-model="pickUpDate"
                    :monday-first="true"
                    input-class="form-control date-picker"
                    :language="sv"
                  )
            .col-md-6
              .input-group.input-group_date-time.m-t-15
                .input-group-prepend
                  span.input-group-text
                    i.far.fa-clock
                select#step-time.custom-select(
                  v-model="pickUpTime"
                  v-validate="'required'"
                  data-vv-name="pickUpTime"
                  :class="{'is-invalid': errors.has('pickUpTime')}"
                )
                  option(selected="" disabled="" value="") {{ $t("steps.costs.chooseTime") }}
                  option(
                    v-for="(item, index) in times"
                    :value="item.time"
                    :key="index"
                  ) {{ item.time }}


        .form-group
          label.custom-check.m-t-10(
            v-if="terms.active"
            for="confirm"
            :class="{'is-invalid':errors.has('confirmTerm')}"
          )
            input#confirm.custom-check-input(
              type="checkbox"
              data-trig="wheels"
              placeholder=" "
              v-validate="'required'"
              data-vv-name="confirmTerm"
              v-model="confirmTerm"
              :class="{'is-invalid':errors.has('comments:required')}"
            )
            .check
              Check
            .custom-check-text
              a(:href="terms.link" target="_blank") {{ terms.headline }}

        .form-group(v-if="carCareGuide.active && isDanmark === false")
          .custom-check-text.link-new.m-l-35
            a(:href="carCareGuide.link" target="_blank") {{ carCareGuide.headline }}
        .form-group(v-if="carCareGuide.active && isDanmark")
          label.custom-check.m-t-10(
            for="confirm2"
            :class="{'is-invalid':errors.has('careGuide')}"
          )
            input#confirm2.custom-check-input(
              type="checkbox"
              v-validate="'required'"
              data-vv-name="careGuide"
              name="careGuide"
              v-model="careGuide"
            )
            .check
              Check
            .custom-check-text
              a(:href="carCareGuide.link" target="_blank") {{ carCareGuide.headline }}

        AlertBox(v-if="showAlert"
          :title="$t('alertBox.title')"
          :content="$t('alertBox.content')"
        )

        .step-btns
          button.step-btn.btn-hover(:disabled="settings.kalpData && !showCreditInfoForm" @click.prevent="confirmOrder")
            | {{ orderPageButton }}

      .col-5
        .step-title.m-b-10 {{ $t("stepOrder.compilation") }}
        MainVehicleInfo
        DetailedVehicleInfo

        .total-monthly-cost.m-t-15
          .total-monthly-cost-text {{ $t("stepOrder.totalMonthlyCost") }}
          .total-monthly-cost-sum
            span {{calculatedPrice}}
            | {{ currencySymbol }}

        v-galery(
          :main-image-url="vehicleColor && vehicleColor.modelColor && vehicleColor.modelColor.image ? vehicleColor.modelColor.image.image : '' "
          :images="vehicle.images ? vehicle.images.data : []"
          :alt="vehicle ? vehicle.name : ''"
          :showThumbs="false"
        )

      .cost-block.m-t-20
        CostDescription
</template>

<script>
  import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

  import {TheMask} from 'vue-the-mask'

  import ProductStepMixin from '@/mixins/ProductStepMixin'
  import VGalery from '@/components/helps/Galery'
  import {formatPrice, isRental, isDanmark} from "../../../helpers/helpers";
  import AlertBox from '../../common/AlertBox.vue';


  export default {
    name: 'StepOrder',
    data: () => ({
      confirmTerm: false,
      showCreditInfoForm: false,
      careGuide: false,
      postcode: '',
      postcode_validation: '',
      waitingForResponse: false,
      showSelectPickUpLocation: false,
      isDanmark: isDanmark(),
    }),
    mixins: [ ProductStepMixin, ],
    components: {
      VGalery,
      CustomScroll: () => import('@/components/helps/CustomScroll'),
      CloseCircle: () => import('@/components/steps-faceless/CloseCircle.vue'),
      Check: () => import('@/components/svg/check'),
      VehicleDescription: () => import('@/components/steps-faceless/VehicleDescription'),
      MainVehicleInfo: () => import('@/components/steps-faceless/MainVehicleInfo'),
      DetailedVehicleInfo: () => import('@/components/steps-faceless/DetailedVehicleInfo'),
      CostDescription: () => import('@/components/steps-faceless/CostDescription'),
      SelectPickUpLocation: () => import('@/components/steps-faceless/SelectPickUpLocation'),
      TheMask,
      AlertBox,
    },
    created() {
      this.setHomeDelivery(this.deliveryType === 'delivery_type_home');
    },
    computed: {
      ...mapState('global', ['isDisabled']),
      ...mapState('product', [ 'vehicleColor', 'priceForm']),
      ...mapState('reseller', {
        customStyle: state => state.customStyle || '',
        orderPageText: state => state.customStyle.orderPage || '',
        resellerInfo: state => state.resellerInfo,
        terms: state => state.resellerInfo.settings?.terms || {},
        carCareGuide: state => state.resellerInfo.settings?.carCareGuide || {},
        settings: state => state.resellerInfo.settings,
        currencySymbol: state => state.resellerInfo?.market?.currency?.symbol || 'kr',
        orderPageButton: state => state.customStyle.orderPage ?
          state.customStyle.orderPage.submit_btn_text : '',
      }),
      ...mapState('filter', ['finance_form_name']),
      ...mapGetters('filter', ['getFinanceFormName']),
      ...mapGetters('product', [
        'getPersonNumberError',
      ]),
      ...mapGetters('financing', ['totalMonthlyCost']),
      calculatedPrice() {
        return formatPrice(window.location.pathname.includes('/confirmed/')
            ? JSON.parse(localStorage.getItem('vehicleInfo')).price
            : this.totalMonthlyCost
        );
      },
      homeDelivery() {
        return this.isHasPriceData('home_delivery');
      },
      locationId: {
        get() {
          return this.$store.state.order.locationId
        },
        set(value) {
          this.$store.commit('order/setLocationId', value)
        }
      },
      deliveryType: {
        get() {
          return this.$store.state.product.deliveryType
        },
        set(value) {
          this.$store.commit('product/setDeliveryType', value)

          this.setHomeDelivery(this.deliveryType === 'delivery_type_home')
        }
      },
      locations() {
        return this.vehicle.locations ? this.vehicle.locations.data : []
      },
      location() {
        return this.vehicle.locations ? this.vehicle.locations.data.find(item => item.id === this.locationId) : {}
      },
      firstName: {
        get() {
          return this.$store.state.order.firstName
        },
        set(value) {
          this.$store.commit('order/setFirstName', value)
        }
      },
      lastName: {
        get() {
          return this.$store.state.order.lastName
        },
        set(value) {
          this.$store.commit('order/setLastName', value)
        }
      },
      email: {
        get() {
          return this.$store.state.order.email
        },
        set(value) {
          this.$store.commit('order/setEmail', value)
        }
      },
      phone: {
        get() {
          return this.$store.state.order.phone
        },
        set(value) {
          this.$store.commit('order/setPhone', value)
        }
      },
      personNumber: {
        get() {
          return this.$store.state.order.personNumber
        },
        set(value) {
          this.updatePersonNumberError('');

          this.$store.commit('order/setPersonNumber', value)
        }
      },
      companyName: {
        get() {
          return this.$store.state.order.companyName
        },
        set(value) {
          this.$store.commit('order/setCompanyName', value)
        }
      },
      companyNumber: {
        get() {
          return this.$store.state.order.companyNumber
        },
        set(value) {
          this.$store.commit('order/setCompanyNumber', value)
        }
      },
      address: {
        get() {
          return this.$store.state.order.address
        },
        set(value) {
          this.$store.commit('order/setAddress', value)
        }
      },
      coAddress: {
        get() {
          return this.$store.state.order.coAddress
        },
        set(value) {
          this.$store.commit('order/setCoAddress', value)
        }
      },
      city: {
        get() {
          return this.$store.state.order.city
        },
        set(value) {
          this.$store.commit('order/setCity', value)
        }
      },
      zipCode: {
        get() {
          return this.$store.state.order.zipCode
        },
        set(value) {
          this.$store.commit('order/setZipCode', value)
        }
      },
      comments: {
        get() {
          return this.$store.state.order.comments
        },
        set(value) {
          this.$store.commit('order/setComments', value)
        }
      },
			drivingLicense: {
				get() {
					return this.$store.state.order.drivingLicense
				},
				set(value) {
					this.$store.commit('order/setDrivingLicense', value)
				}
      },
      showAlert() {
        return this.resellerInfo.market.lang === 'sv'
      },
    },
    methods: {
      confirmOrder() {
        if (this.validate()) {
          this.$emit('goToConfirm')
        }
      },
      closeSelectPickUpLocation() {
        this.$set(this.$data, 'showSelectPickUpLocation', false)
      },
      showPickUpLocation() {
        this.$set(this.$data, 'showSelectPickUpLocation', true)
      },
      isRental,
      ...mapActions('product', ['updatePersonNumberError', ]),
      ...mapMutations('order', ['setKalpData', 'setHomeDelivery']),
      changeKalpData({ name, value }) {
        this.setKalpData({ name, value: value.target.value });
      },
      isHasPriceData(key) {
        const { prices, } = this.vehicle;

        if (!(prices && prices.data.length)) return;

        const pricesObj = prices.data.find(item => item.finance_form === this.finance_form_name);

        return pricesObj ? pricesObj[key] : 0
      },
      checkPostCode() {
        if (this.postcode) {
          this.$set(this.$data, 'waitingForResponse', true)
          // eslint-disable-next-line no-undef
          axios.get('/postal-code', {
            headers: {
              'Authorization': this.partnerKey,
            },
            params: {
              postcode: this.postcode,
            }
          }).then(res => {
            this.$set(this.$data, 'waitingForResponse', false)

            if (res.status === 404) {
              this.$set(this.$data, 'postcode_validation', 'invalid')
            } else if (res.status === 200) {
              this.$set(this.$data, 'postcode_validation', 'valid')
              this.setPostalCodeId(res.data.id);
            }
          }).catch(error => {
            this.$set(this.$data, 'waitingForResponse', false)

            if(error.response.status === 404) {
              this.$set(this.$data, 'postcode_validation', 'invalid')
            }
          })
        }
      },
      postcodeInput() {
        this.$set(this.$data, 'postcode_validation', '')
      },
    }
  }
</script>

<style lang="sass">
.step-order-new
  .cost-block
    background: #F7F7F7 !important
    border: 1px solid #BABABA
    box-sizing: border-box
    padding: 20px 50px !important
    font-size: 10px
    line-height: 12px
    color: #000000 !important
  .form-control
    border: none
    border-radius: initial
    border-bottom: 0.5px solid #6C6C6C
    &:focus
      outline: none
      box-shadow: none

.pick-up-location-wrapper
  position: absolute
  top: 0
  background: #F7F7F7
  z-index: 99
  padding: 66px 0
  min-width: 567px
  min-height: 900px
  left: -1000%
  transition: left .5s ease-in-out
  &--active
    left: 0
.pick-up-location
  padding: 0 33px 0 63px
  display: flex
  align-items: center
  margin-bottom: 0
  position: relative
  input
    display: none
  .pick-up-location-textbox
    display: flex
    justify-content: space-between
    align-items: center
    width: 100%
    padding: 4px 0
    min-height: 80px
    border-bottom: 0.5px solid #6C6C6C
    div
      position: relative
      z-index: 10
  .pick-up-location-textbox.selected
    border-bottom: transparent
.pick-up-location-title
  margin-bottom: 110px
  text-align: center
  letter-spacing: 0.07em
  text-transform: uppercase
  font-size: 30px

.selected-bg
  position: absolute
  width: 100%
  height: 103%
  left: 0
  top: -2px
  z-index: 9
  background: transparent

.pick-up-location input:checked + .selected-bg
  background: #EFDF00

.pick-up-location:hover .selected-bg
  cursor: pointer
  background: #EFDF00

.pick-up-location input:checked .pick-up-location-textbox
  border: none

.location-custom-scroll
  height: 575px
</style>