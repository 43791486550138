<template lang="pug">
.page.page-model(v-if="active")
  .container
    .row
      .col-md-6.order-md-1.order-2
        .m-b-15
          v-galery(
            :main-image-url="vehicleColor && vehicleColor.modelColor && vehicleColor.modelColor.image ? vehicleColor.modelColor.image.image : '' "
            :images="vehicle.images ? vehicle.images.data : []"
            :alt="vehicle ? vehicle.name : ''"
          )
      .col-md-6.order-md-2.order-1.vehicle-desc-block
        VehicleDescription(
          :title="vehicleTitle"
          :description="fullVehicleSubName"
        )

    .row.m-t-10
      .col-md-6
        .d-none.d-md-block
          .step-title 3. {{ customStyle.title }}
        .d-md-none.d-block
          .step-subtitle {{ customStyle.title }}
        .step-text.m-t-10(v-html="customStyle.intro")

    .row.m-t-20
      .col-md-6
        .row.m-t-20
          .col-md-6
            .form-group
              label(for="first_name") {{ $t("stepOrder.firstName") }}
              input#first_name.form-control(
                type="text"
                v-model="firstName"
                v-validate="'required|alpha_spaces'"
                data-vv-name="firstName"
                :class="{'is-invalid':errors.has('firstName')}"
              )

          .col-md-6
            .form-group
              label(for="last_name") {{ $t("stepOrder.secondName") }}
              input#last_name.form-control(
                type="text"
                v-model="lastName"
                v-validate="'required|alpha_spaces'"
                data-vv-name="lastName"
                :class="{'is-invalid':errors.has('lastName')}"
              )

        .form-group(v-if="['Corporate leasing', 'Corporate rental', 'VAT credit'].includes(getFinanceFormName)")
          label(for="company_name") {{ $t("stepOrder.companyName") }}
          input#company_name.form-control(
            type="text"
            v-model="companyName"
            v-validate="'required|alpha_spaces'"
            data-vv-name="companyName"
            :class="{'is-invalid':errors.has('companyName')}"
          )

        .row
          .col-md-12(v-if="['Corporate leasing', 'Corporate rental', 'VAT credit'].includes(getFinanceFormName)")
            .form-group
              label(for="company_number") {{ $t("stepOrder.organizationNumber") }}
              the-mask.form-control(
                id="company_number"
                mask="######-####"
                v-model="companyNumber"
                v-validate="'required|min:10|max:10'"
                data-vv-name="companyNumber"
                :class="{'is-invalid':errors.has('companyNumber')}"
              )

          .col-md-12(v-if="settings.personNumber")
            .form-group
              label(for="person_number") {{ $t("stepOrder.securityNum") }} (ÅÅÅÅMMDD-NNNN)
              the-mask.form-control(
                id="person_number"
                mask="########-####"
                v-model="personNumber"
                v-validate="'required|min:12|max:12'"
                data-vv-name="personNumber"
                :class="{'is-invalid':errors.has('personNumber') || getPersonNumberError !== ''}"
              )

            .form-group(v-if="settings.drivingLicense")
              label(for="drivingLicense") {{ $t("stepOrder.drivingLicense") }}
              the-mask.form-control(
                id="drivingLicense"
                mask="#########"
                v-model="drivingLicense"
                v-validate="'required|numeric|min:9|max:9'"
                data-vv-name="drivingLicense"
                :class="{'is-invalid':errors.has('drivingLicense')}"
              )

            .input-group.m-t-15.m-b-20(
              v-show="getPersonNumberError !== ''"
              class="postcode_validation invalid"
            ) {{ getPersonNumberError }}

        .form-group
          label(for="inp6") {{ $t("stepOrder.adress") }}
          input#inp6.form-control(
            type="text"
            v-model="address"
            v-validate="'required'"
            data-vv-name="address"
            :class="{'is-invalid':errors.has('address')}"
          )

        .form-group
          label(for="inp7") {{ $t("stepOrder.coAdress") }}
          input#inp7.form-control(
            type="text"
            v-model="coAddress"
            data-vv-name="coAddress"
            :class="{'is-invalid':errors.has('coAddress')}"
          )

        .row
          .col-md-4
            .form-group
              label(for="zip_code") {{ $t("stepOrder.postnum") }}
              the-mask.form-control(
                id="zip_code"
                type="text"
                v-model="zipCode"
                v-validate="'required|numeric|min:4|max:5'"
                data-vv-name="zipCode"
                mask="#####"
                :class="{'is-invalid':errors.has('zipCode')}"
              )

          .col-md-8
            .form-group
              label(for="inp10") {{ $t("stepOrder.city") }}
              input#inp10.form-control(
                type="text"
                v-model="city"
                v-validate="'required'"
                data-vv-name="city"
                :class="{'is-invalid':errors.has('city')}"
              )

        .form-group
          label(for="inp11") {{ $t("stepOrder.telephone") }}
          transition(name="fade")
            div(v-if="errors.has('phone')" class="validation-hint") {{ $t("stepOrder.telephoneHint") }}
          input#inp11.form-control(
            type="tel"
            v-model="phone"
            v-validate="{required: true, max:13, regex: phoneRegex}"
            mask="############"
            data-vv-name="phone"
            :class="{'is-invalid':errors.has('phone')}"
          )

        .form-group
          label(for="inp12") {{ $t("stepOrder.email") }}
          input#inp12.form-control(
            type="email"
            v-model="email"
            v-validate="{required: true, regex:/^[a-z0-9._-]+@([a-z0-9])+[a-z0-9.-]+([.])+[a-z]{2,4}$/}"
            data-vv-name="email"
            :class="{'is-invalid':errors.has('email')}"
          )

        .form-group(v-if="settings.drivingLicense")
          label(for="drivingLicense") Körkortsnummer (fält 4d på ditt körkort, 9 siffror)
          the-mask.form-control(
            id="drivingLicense"
            mask="#########"
            v-model="drivingLicense"
            v-validate="'required|numeric|min:9|max:9'"
            data-vv-name="drivingLicense"
            :class="{'is-invalid':errors.has('drivingLicense')}"
          )

        .form-group
          label(for="inp13") {{ $t("stepOrder.comment") }}
          textarea#inp13.form-control(
            rows="4"
            v-model="comments"
          )

        .form-group
          label.custom-check.m-t-10(
            v-if="terms.active"
            for="confirm"
            :class="{'is-invalid':errors.has('confirmTerm')}"
          )
            input#confirm.custom-check-input(
              type="checkbox"
              data-trig="wheels"
              v-validate="'required'"
              data-vv-name="confirmTerm"
              v-model="confirmTerm"
              :class="{'is-invalid':errors.has('comments:required')}"
            )
            .check
              Check
            .custom-check-text
              a(:href="terms.link" target="_blank") {{ terms.headline }}

        .form-group
          .custom-check-text.link-regular.m-l-35
          a(:href="carCareGuide.link" target="_blank") {{ carCareGuide.headline }}
          label.custom-check.m-t-10(
            v-if="carCareGuide.active"
            for="confirm2"
            :class="{'is-invalid':errors.has('сareGuide')}"
          )
            input#confirm2.custom-check-input(
              type="checkbox"
              v-validate="'required'"
              data-vv-name="сareGuide"
              name="сareGuide"
              v-model="сareGuide"
            )
            .check
              Check
            .custom-check-text


          input#GACLIENTID(type='hidden' name='GACLIENTID' v-model='gaClientId')
          input#GATRACKID(type='hidden' name='GATRACKID' v-model='googleAcc')

        //- CREDIT INFORMATION FORM
        template(v-if="settings.kalpData && showCreditInfoForm")
          .d-none.d-md-block.m-t-20
            .step-title {{ $t("creditInfo.title") }}
          .d-md-none.d-block.m-t-20
            .step-subtitle {{ $t("creditInfo.subtitle") }}
          .step-text.m-t-10 {{ $t("creditInfo.description") }}

          .form-group.m-t-20(v-for="kaplField in settings.kalpFields.data")
            label {{ kaplField.headline }}

            select.form-control(
              v-if="kaplField.type === 'dropdown'"
              @input="changeKalpData({ name: kaplField.headline, value: $event })"
              :data-vv-name="kaplField.headline"
              :class="{'is-invalid':errors.has(kaplField.headline)}"
            )
              option(selected disabled value="") {{ kaplField.placeholder }}
              option(
                v-for="(item, index) in kaplField.dd_items"
                :value="item"
              ) {{ item }}


            input.form-control(
              v-if="kaplField.type === 'number'"
              type="number"
              @input="changeKalpData({ name: kaplField.headline, value: $event })"
              v-validate="'required|numeric'"
              :data-vv-name="kaplField.headline"
              :placeholder="kaplField.placeholder"
              :class="{'is-invalid':errors.has(kaplField.headline)}"
            )

            input.form-control(
              v-if="kaplField.type === 'text'"
              type="text"
              @input="changeKalpData({ name: kaplField.headline, value: $event })"
              v-validate="'required'"
              :data-vv-name="kaplField.headline"
              :placeholder="kaplField.placeholder"
              :class="{'is-invalid':errors.has(kaplField.headline)}"
            )

        .step-btns.m-t-20
          button.step-btn.btn-hover(
            @click.prevent="showCreditInfoForm = !showCreditInfoForm"
            v-show="settings.kalpData && !showCreditInfoForm"
          )
            | {{ $t("creditInfo.btnText") }}
            i.fas.fa-chevron-right

        .step-btns.m-t-20.d-none.d-lg-block
          button.step-btn.btn-hover(:disabled="isDisabled || hasErrors || (settings.kalpData && !showCreditInfoForm)" @click.prevent="goToNext")
            | {{ orderPageButton }}
            i.fas.fa-chevron-right

        .step-subtitle.m-t-20 {{ customStyle.what_next_text }}

        div.m-b-20(v-if="customStyle.items && !!customStyle.items.data.length")
          .advant.m-t-20(v-for="item in customStyle.items.data")
            .advant-img
              img(
                alt="icon"
                :src="item.image"
              )
            .advant-info
              .advant-title {{ item.title }}
              .advant-text {{ item.description }}

      .col-md-6.mobile-m-t-30
        .step-title.mobile-m-b-10 {{ $t("stepOrder.compilation") }}
        MainVehicleInfo
        DetailedVehicleInfo

        AlertBox(v-if="showAlert"
          :title="$t('alertBox.title')"
          :content="$t('alertBox.content')"
        )

        .cost-block.m-t-20
          CostDescription
</template>

<script>
  import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

  import {TheMask} from 'vue-the-mask'

  import ProductStepMixin from '@/mixins/ProductStepMixin'
  import VGalery from '@/components/helps/Galery'
  import AlertBox from '../../common/AlertBox.vue';

  export default {
    name: 'StepOrder',
    data: () => ({
      confirmTerm: false,
      showCreditInfoForm: false,
      сareGuide: false,
    }),
    mixins: [ ProductStepMixin, ],
    components: {
      VGalery,
      Check: () => import('@/components/svg/check'),
      VehicleDescription: () => import('@/components/steps/VehicleDescription'),
      MainVehicleInfo: () => import('@/components/steps/MainVehicleInfo'),
      DetailedVehicleInfo: () => import('@/components/steps/DetailedVehicleInfo'),
      CostDescription: () => import('@/components/steps/CostDescription'),
      TheMask,
      AlertBox,
    },
    computed: {
      ...mapState('global', ['isDisabled']),
      ...mapState('product', [ 'vehicleColor', ]),
      ...mapState('reseller', {
        customStyle: state => state.customStyle.orderPage || '',
        resellerInfo: state => state.resellerInfo,
        terms: state => state.resellerInfo.settings?.terms || {},
        carCareGuide: state => state.resellerInfo.settings?.carCareGuide || {},
        settings: state => state.resellerInfo.settings,
        orderPageButton: state => state.customStyle.orderPage ?
          state.customStyle.orderPage.submit_btn_text : '',
      }),
      ...mapState('filter', ['finance_form_name']),
      ...mapGetters('filter', ['getFinanceFormName']),
      ...mapGetters('product', [
        'getPersonNumberError',
      ]),
      firstName: {
        get() {
          return this.$store.state.order.firstName
        },
        set(value) {
          this.$store.commit('order/setFirstName', value)
        }
      },
      lastName: {
        get() {
          return this.$store.state.order.lastName
        },
        set(value) {
          this.$store.commit('order/setLastName', value)
        }
      },
      email: {
        get() {
          return this.$store.state.order.email
        },
        set(value) {
          this.$store.commit('order/setEmail', value)
        }
      },
      phone: {
        get() {
          return this.$store.state.order.phone
        },
        set(value) {
          this.$store.commit('order/setPhone', value)
        }
      },
      personNumber: {
        get() {
          return this.$store.state.order.personNumber
        },
        set(value) {
          this.updatePersonNumberError('');

          this.$store.commit('order/setPersonNumber', value)
        }
      },
      companyName: {
        get() {
          return this.$store.state.order.companyName
        },
        set(value) {
          this.$store.commit('order/setCompanyName', value)
        }
      },
      companyNumber: {
        get() {
          return this.$store.state.order.companyNumber
        },
        set(value) {
          this.$store.commit('order/setCompanyNumber', value)
        }
      },
      address: {
        get() {
          return this.$store.state.order.address
        },
        set(value) {
          this.$store.commit('order/setAddress', value)
        }
      },
      coAddress: {
        get() {
          return this.$store.state.order.coAddress
        },
        set(value) {
          this.$store.commit('order/setCoAddress', value)
        }
      },
      city: {
        get() {
          return this.$store.state.order.city
        },
        set(value) {
          this.$store.commit('order/setCity', value)
        }
      },
      zipCode: {
        get() {
          return this.$store.state.order.zipCode
        },
        set(value) {
          this.$store.commit('order/setZipCode', value)
        }
      },
      comments: {
        get() {
          return this.$store.state.order.comments
        },
        set(value) {
          this.$store.commit('order/setComments', value)
        }
      },
			drivingLicense: {
				get() {
					return this.$store.state.order.drivingLicense
				},
				set(value) {
					this.$store.commit('order/setDrivingLicense', value)
				}
      },
      showAlert() {
        return this.resellerInfo.market.lang === 'sv'
      },
    },
    methods: {
      ...mapActions('product', ['updatePersonNumberError', ]),
      ...mapMutations('order', ['setKalpData']),
      changeKalpData({ name, value }) {
        this.setKalpData({ name, value: value.target.value });
      }
    }
  }
</script>
